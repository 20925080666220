import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CommonTypes } from '@organicapps/organictypes';
import { IProductPageParams } from '../HOCs/withProductGuard';
import { getUserPlatform } from '../helpers/getUserPlatform';
import { INSTANT_APP_LINK } from '../constants/links';
import { useUserStore } from '../store';

export interface IInstantAppProps {
  source: string;
  spot: string;
}

export const useInstantApp = ({ source, spot }: IInstantAppProps) => {
  const [params] = useSearchParams();
  const { productName } = useParams<keyof IProductPageParams>() as IProductPageParams;

  const [invocationUrl, setInvocationUrl] = useState<CommonTypes.Nullable<string>>(undefined);

  const { appUserToken } = useUserStore((state) => ({
    appUserToken: state.appUserToken,
  }));

  useEffect(() => {
    const platform = getUserPlatform();
    if (!platform) {
      return;
    }

    const link = INSTANT_APP_LINK[platform][productName];
    if (link) {
      setInvocationUrl(`${link}&token=${appUserToken}&instantAppSource=${source}&instantAppSpot=${spot}`);
    }
  }, [productName, params, appUserToken]);

  const invoke = (fallback?: string) => {
    const url = invocationUrl || fallback;
    if (url) {
      window.location.href = url;
    }
  };

  return {
    invoke,
  };
};
